import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const RedirecContent = <div>Redirecting you to the login page...</div>;
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => RedirecContent,
  });

  return <Component />;
};

export default ProtectedRoute;
