import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import ProtectedRoute from "./ProtectedRoute";

const PrivateRoute: React.FC = () => {
  const Home = lazy(() => import("../pages/home/Home"));
  const UIExample = lazy(() => import("../pages/example/ui-example"));
  const Milestone = lazy(() => import("../pages/milestone/milestone"));

  const JdCatalogList = lazy(() => import("../pages/jd-catalog-list/Jd-Catalog-List"));
  const JdCatalogCreate = lazy(() => import("../pages/jd-catalog-create/Jd-Catalog-Create"));
  const JdTalentTypeList = lazy(() => import("../pages/jd-talent-type/Jd-Talent-Type-List"));
  const JdAbilityList = lazy(() => import("../pages/jd-abilities/Jd-Ability-List"));  
  const JdKnowledgeList = lazy(() => import("../pages/jd-knowledge/Jd-Knowledge-List"));
  const JdSkillList = lazy(() => import("../pages/jd-skills/Jd-Skill-List"));
  const JdTaskList = lazy(() => import("../pages/jd-tasks/Jd-Task-List"));
  const JdTechnicalList  = lazy(() => import("../pages/jd-technical-skills/Jd-Technical-Skill-List"));
  const JdToolUsedList = lazy(() => import("../pages/jd-tool-used/Jd-Tool-Used-List"));
  const JdJobTitlesList  = lazy(() => import("../pages/jd-job-titles/Jd-Job-Titles-List"));
  const JdServiceTypeList = lazy(() => import("../pages/jd-service-type/Jd-Service-Type-List"));

  const layoutStyle: React.CSSProperties = {
    padding: "0 24px 24px",
    backgroundColor: '#fff'
  };

  return (
    <Layout style={layoutStyle}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<ProtectedRoute component={JdCatalogList} />} />
          <Route path="/list-jd-catalog"  element={<ProtectedRoute component={JdCatalogList} />}/>  
          <Route path="/create-jd-catalog"  element={<ProtectedRoute component={JdCatalogCreate} />}/>    
          <Route path="/create-jd-catalog/:action/:id"  element={<ProtectedRoute component={JdCatalogCreate} />}/>     
          <Route path="/ui-example" element={<UIExample />} />
          <Route path="/milestone"  element={<ProtectedRoute component={Milestone} />}/>  
          <Route path="/talent-type"  element={<ProtectedRoute component={JdTalentTypeList} />}/> 
          <Route path="/ability"  element={<ProtectedRoute component={JdAbilityList} />}/>
          <Route path="/knowledge"  element={<ProtectedRoute component={JdKnowledgeList} />}/>
          <Route path="/skill"  element={<ProtectedRoute component={JdSkillList} />}/>
          <Route path="/task"  element={<ProtectedRoute component={JdTaskList} />}/>
          <Route path="/technical"  element={<ProtectedRoute component={JdTechnicalList} />}/>  
          <Route path="/tool-used"  element={<ProtectedRoute component={JdToolUsedList} />}/>
          <Route path="/job-titles"  element={<ProtectedRoute component={JdJobTitlesList} />}/>
          <Route path="/service-type"  element={<ProtectedRoute component={JdServiceTypeList} />}/>  
        </Routes>
      </Suspense>
    </Layout>
  );
};


export default PrivateRoute;