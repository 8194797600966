import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./components/layout/header/AppHeader";
import AppContent from "./components/layout/content/Content";
// import AppHeaderLib from "./components/layout/header/HeaderLib";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";

const App: React.FC = () => {
  const path = "/milestone";
  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();
  React.useEffect(() => {
    console.log('logout:::')
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({ appState: { targetUrl: path } });
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, path]);

  return (
    <React.Fragment>
      {user &&
        <BrowserRouter>
          <Layout>
            <AppHeader />
            {/* <AppHeaderLib /> */}
            <AppContent />
          </Layout>
        </BrowserRouter>
      }
    </React.Fragment>
  );
}

export default App;
