import React from "react";
import { Layout } from "antd";
import { ErrorBoundary } from 'react-error-boundary'; // https://www.npmjs.com/package/react-error-boundary
import AppSidebar from "../sidebar/Sidebar";
import PrivateRoute from "../../routes/PrivateRoute";
import "./Content.css";

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return ( <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  )
}

const Content: React.FC = () => {
  return (
    <Layout>
      <AppSidebar />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <PrivateRoute />
      </ErrorBoundary>
    </Layout>
  );
}

export default Content;
