// src/auth/auth0-provider-with-history.js

import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import  environment from '../../../environments/environment';
interface IProps {
  children?: ReactNode
}


const Auth0ProviderWithNavigate = ({ children }: IProps) => {
  const AUTH0_DOMAIN =  environment.auth.CLIENT_DOMAIN;
  const AUTH0_CLIENT_ID = environment.auth.CLIENT_ID;

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
      cacheLocation={"localstorage"}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
